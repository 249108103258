.App {
  text-align: center;
}

.App-header {
  /* background-color: #282c34; */
  min-height: 7vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* font-size: calc(10px + 2vmin); */
}

.App-link {
  color: #61dafb;
}

.div-body{
  margin-top: 70px;
  min-height: calc(100vh - 120px - 100px);
}
.building-img{
  width: 1000px;
}


.div-footer{
  margin-top: auto;
  padding-top: 50px;
}